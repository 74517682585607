@import 'variables';
@import 'fonts/ubuntu';
@import 'fonts/ubuntumono';
@import 'overrides';
@import 'wallet';
@import 'history';
@import 'coins';
@import 'ui';
@import 'activities';
@import 'coin';

html, body {
    background: #ffffff;
    font-family: $font_stack;
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

input, textarea, select, button {
    font-family: $font_stack;
}

textarea {
    box-shadow: none !important;
    appearance: none;
}

input {
    text-overflow: ellipsis; 
}

.is-ios {
    .ios-translucent-bar {
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        background: #000;
        height: env(safe-area-inset-top);
    }
}

.macos-translucent-bar {
    display: block;
    height: $macos_pseudo_titlebar_height;
    background: #FFF;
    width: 100%;
    -webkit-app-region: drag;
    user-select: none;
}

.d8-container {
    display: block;
    margin: auto;
    background: #FFF;
    border-radius: 14px;
    overflow: hidden;
    padding: 20px;
    max-width: 380px; 
}

.d8wt {
    &__section {
        margin-bottom: 22px;
        &:last-child {
            margin-bottom: 0;
        }
        label {
            line-height: 12px;
        }
    }
}

.d8-dash {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &_item {
        flex: 1;
        display: flex;
        justify-content: space-around;
        border-left: 1px solid #EEE;
        cursor: pointer;
        &--settings svg {
            transform: scale(0.9);
        }
        &--spinner {
            pointer-events: none;
        }
        svg {
            width: 48px;
            height: 48px;
            fill: #DDD;
            transition: .2s all ease-in-out;
        }
        &:hover {
            svg {
                fill: $aurora_blue;
            }
        }
        &:first-child {
            border: none;
        }
    }
}
.rotating svg {
    fill: $aurora_blue;
    animation: meatspin 1.5s linear infinite;
}

.d8-autocomplete {
    width: 100%;
    &__items {
        max-width: 380px;
    }
}
.d8-autocomplete-item {
    display: flex;
    justify-content: space-between;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__name {
        font-weight: 600;
        color: #555;
        max-width: 50%;
        flex-shrink: 0;
        padding-right: 8px;
        font-size: 14px;
        &:empty {
            display: none;
        }
    }
    &__pkey {
        color: #BEBEBE;
        font-size: 0;
        &::before, &::after {
            font-size: 14px;
        }
    }
}

/**
 * Блок настроек:
 */
.d8wt-settings {
    display: block;
    margin: 0 0 30px;
    font-size: 16px;
    text-align: center;
}

.d8wt-popper-trigger {
    font-weight: 600;
    color: #409eff;
    cursor: pointer;
    &::after {
        content: attr(data-after);
    }
    &:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: 600px) {
    .el-main {
        padding: 0;
    }
    .start-button {
        max-width: 380px;
        margin: auto;
    }
    .d8wt__section--mobile-float-bottom {
        position: fixed;
        bottom: 20px;
        left: 20px;
        right: 20px;
        margin-bottom: 0;
    }
}

@keyframes meatspin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.el-button--dark {
    &.is-disabled {
        &, &:hover, &:focus, &:active {
            background-color: #c7cace;
            border-color: #c7cace;
        }
    }
    &, &:focus {
        background-color: #282828;
        border-color: #282828;
        color: #FFF;
    }
    &:hover {
        background-color: #121212;
        border-color: #121212;
        color: #FFF;
    }
    &:active {
        background-color: #000000;
        border-color: #000000;
        color: #FFF;
    }
}
