.d8-coin {
    display: flex;
    &__avatar {
        width: 28px;
        height: 28px;
        align-self: flex-start;
        flex-shrink: 0;
        border-radius: 50%;
        overflow: hidden;
        margin: -6px 6px -6px -4px;
        background-color: #EEE;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        padding-left: 6px;
        min-width: 0;
        margin-bottom: -4px;
    }
    &__balance, &__symbol {
        font-weight: 700;
    }
    &__symbol--small {
        color: #BBB;
        font-size: 0.8em;
    }
    &__title {
        margin: -2px 0 10px 0;
        padding-bottom: 2px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__info-icon {
        color: #DDD;
        padding: 6px;
        margin: -6px 0 -6px -2px;
        &:hover {
            color: #555;
        }
    }
    &__info-description {
        margin: -2px 0 6px 0;
        font-style: italic;
    }
    &__info-table {
        tr td {
            &:first-child {
                color: #777;    
                padding-right: 6px;
                font-weight: 600;
            }
            padding: 3px 0 0 0;
        }
    }
    &__exchange-rates {
        color: #888;
        font-size: 12px;
    }
}
