/**
 * Маленький тултип с кнопкой удаления:
 */
.ui-minipopper {
    &__text {
        margin: 4px 0 12px 0;
    }
    &__button {
        width: 100%;
    }
}

/**
 * Длинная кнопка, разделённая на две части (типа как кошелек и баланс):
 */
.button-with-postfix {
    display: flex;
    &__left {
        max-width: 70%;
        flex-grow: 1;
        flex-basis: 100%;
        border-radius: 4px 0px 0px 4px;
        &:active, &:hover, &:focus {
            color: #409eff !important;
            background: #ecf5ff !important;
            border-color: #b3d8ff !important;
        }
        &:hover {
            color: darken(#409eff, 8%) !important;
            background: darken(#ecf5ff, 1%) !important;
            border-color: darken(#b3d8ff, 2%) !important;
        }
    }
    &__right {
        flex: 1;
        border-radius: 0px 4px 4px 0px;
        border-left: none;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        margin-left: 0 !important;
        &::after {
            content: " BIP";
            opacity: 0.75;
            font-size: 9px;
            vertical-align: top;
        }
        &:active, &:hover, &:focus {
            color: #616366 !important;
            border-color:#dcdfe6 !important;
        }
    }
}

/**
 * Блок с длинным текстом, который разделён посередине многоточием:
 */
.ui-looong {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &::before {
        flex-grow: 0;
        content: attr(data-loopa);
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &::after {
        flex-shrink: 0;
        content: attr(data-poopa);
    }
}

/**
 * Большая синяя кнопка:
 */
.button--fat {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    width: 100%;
    justify-content: center;
    text-decoration: none;
    & > span {
        display: flex;
        align-items: center;
        z-index: 900;
    }
    svg {
        margin-right: 6px;
        width: 24px;
        height: 24px;
        fill: #FFF;
    }
    & + & {
        margin: 10px 0 0 0;
    }
    &.working {
        text-shadow: 1px 1px 2px darken($aurora_blue, 10%);
        &:hover::after {
            background-image: 
                repeating-linear-gradient(
                    -45deg, 
                    lighten($aurora_blue, 4%), 
                    lighten($aurora_blue, 4%) 25px,
                    lighten($aurora_blue, 1%) 25px,
                    lighten($aurora_blue, 1%) 50px
            );
        }
        &::after,
        &:active::after,
        &:focus::after {
            z-index: 400;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: 
                repeating-linear-gradient(
                    -45deg, 
                    $aurora_blue, 
                    $aurora_blue 25px,
                    darken($aurora_blue, 5%) 25px,
                    darken($aurora_blue, 5%) 50px
            ) !important;
            animation: barberpole 1.2s linear infinite;
            background-size: 150% 100%;
            background-position: 0 0;
        }
    }
}

@keyframes barberpole {
    100% {
        background-position: -70px 0px;
    }
} 

.ui-alert {
    display: flex;
    align-items: center;
    width: 100%;
    color: #808388;
    background: #f4f4f5;
    border-radius: 4px;
    padding: 10px 14px;
    font-size: 13px;
    box-sizing: border-box;
    
    svg {
        fill: #909399;
        margin-right: 1ex;
    }
}

.d8-popper-dropdown {
    &__container {
        margin: -4px -12px;
        .el-dropdown-menu__item {
            display: flex;
            align-items: center;
            padding-left: 12px;
            color: #777;
            text-decoration: none;
            svg {
                margin-right: 6px;
                width: 24px;
                height: 24px;
                fill: #CCC;
            }
            &:hover {
                color: darken(#0069ff, 6%);
                background: lighten(#0069ff, 48%);
                svg {
                    fill: #0069ff;
                }
            }
        }
        .el-dropdown-menu__item--divided {
            padding-top: 6px;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 480px) {
    .hidden-m {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .wallet-dialog--m-fullscreen {
        background: #FFF;
        .el-dialog {
            margin: 0 !important;
            max-width: unset;
            border-radius: 0;
            box-shadow: none;
        }
        .el-dialog__body {
            height: 100%;
        }
        &.dialog-fade-enter-active {
            animation: dialog-mobile-fade-in .3s;
        }
        &.dialog-fade-leave-active {
            animation: dialog-mobile-fade-out .2s;
        }
        .el-dialog__footer,
        .el-dialog__header {
            display: none;
        }
        .active-view-root {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .active-view {
            overflow-y: auto;
            scrollbar-width: thin;
            height: 100%;
        }
        .active-view__header {
            margin: 0;
            border-bottom: 1px solid darken($material_main_color, 5%);
            background: $material_main_color;
            border-radius: 0;
            color: #FFF;
            padding-top: calc(13px + env(safe-area-inset-top));
            .el-page-header__content {
                color: #FFF;
                font-weight: 500;
            }
        }
        .active-view__footer {
            margin: auto 0 0 0;
            border-radius: 0;
            top: 0;
        }
        .el-tabs {
            border-color: $material_main_color;
            background: darken($material_main_color, 3%);
            &__active-bar {
                background-color: lighten($material_accent_color, 15%);
                height: 3px;
            }
            &__nav-wrap {
                padding: 0;
            }
            &__item {
                color: lighten($material_main_color, 36%);
                font-weight: 500;
                font-size: 14px;
                padding: 0;
                &.is-active {
                    color: #FFF;
                }
            }
        }
        .active-view-header-group {
            z-index: 600;
            background: $material_main_color;
        }
    }
}

@keyframes dialog-mobile-fade-in {
    0% {
        transform: translate3d(40px, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@keyframes dialog-mobile-fade-out {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(40px, 0, 0);
        opacity: 0;
    }
}
