.tx-history-reward-gross {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #888;
    font-weight: 500;
    padding: 10px 18px;
    font-size: 12px;
    background: #FCFCFC;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 18px;
    &__title {
        text-transform: uppercase;
        color: #AAA;
    }
    &__sum {
        font-size: 14px;
    }
    &__amount {
        color: #303133;
    }
}

.d8-stats__inner-title {
    color: #333;
    font-size: 20px;
    margin: 0 0 -1px 0;
    padding: 20px;
    border-top: 1px solid #eeeeee;
    position: relative;
}
.d8-stats-list {
    &__empty-block {
        display: flex;
        width: 100%;
        min-height: 180px;
        align-items: center;
        justify-content: center;
        span {
            line-height: 60px;
            width: auto;
            color: #909399;
            text-align: center;
        }
    }
    &__description {
        border-top: 1px solid #EEE;
        padding: 16px 20px;
    }
    &__container {
        text-decoration: none;
        border-top: 1px solid #EEE;
        display: flex;
        margin: 0;
        align-items: center;
        padding: 16px 20px;
    }
    &__icon {
        margin: 0 6px 0 -4px;
        fill: #7fc150;
        font-size: 0;
        display: flex;
        align-items: center;
        &--muted {
            fill: #BBB;
        }
        &--violet {
            fill: #5b37d5;
        }
    }
    &__validator {
        overflow: hidden;
        font-size: 13px;
        flex: 1;
        padding: 0 0 0 16px;
        color: #999;
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &::before {
            white-space: nowrap;
        }
    }
    &__timestamp {
        flex-grow: 1;
        text-align: right;
        font-size: 13px;
        color: #666;
    }
    &__reward {
        font-size: 16px;
        font-weight: 600;
        padding: 0 0 0 6px;
        color: #555;
    }
    &__coin {
        color: #BBB;
        font-size: 0.8em;
    }
    &__kukuruzer {
        flex: 1;
    }
    &__action-btn {
        margin: -6px 0;
        &:hover {
            color: #0069ff;
        }
        color: #BBB;
    }
    &__collapse {
        border-top: none;
        border-bottom: none;
        .el-collapse-item {
            &__header {
                line-height: 1;
                height: auto;
                &:hover {
                    background: unset;
                    @at-root {
                        @media (any-hover: hover) {
                            & { background: #f5f7fa; }
                        }
                    }
                }
            }
            &__arrow {
                margin: 0 -4px 0 8px;
            }
            &__content {
                padding-bottom: 0;
            }
        }
    }
    &__subtable {
        width: 100%;
        border-collapse: collapse;
        border-top: 1px solid #e5e5e5;
        tr {
            td {
                padding: 6px;
                font-size: 12px;
                border-bottom: 1px solid #e5e5e5;
            }
            td:first-child {
                color: #333;
                background: #fafafa;
                padding-left: 16px;
                font-weight: 600;
                border-right: 1px solid #e5e5e5;;
            }
            td:last-child {
                padding-right: 16px;
                padding-left: 10px;
            }
        }
        a {
            display: inline-block;
            margin-bottom: -2px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 210px;
            font-weight: 600;
            text-decoration: none;
            color: #444;
            border-bottom: 1px solid;
            line-height: 1;
            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
    &__nopayload {
        color: #bbb;
    }
    &__inline-actions {
        display: flex;
        padding: 0 20px 20px 20px;
    }
}
