.coin-list {
    padding-top: 6px;
    min-height: 180px;
}

.coin-list-search {
    border-bottom: 1px solid #e4e7ed;
    margin: 0 0 6px;
    padding: 2px 10px 1px;
    position: sticky;
    top: 0;
    background: #FFF;
    z-index: 1000;
    .el-input__prefix {
        display: flex;
        align-items: center;
        svg {
            width: 20px;
            height: 20px;
            fill: #cecece;
            transform: scaleX(-1);
        }
    }
    input {
        border: none;
        border-radius: 0;
        padding-left: 32px !important;
    }
}

.coin-list-item {
    line-height: 1;
    height: auto;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    color: #303133;
    &:hover {
        background: #fdfdfd;
    }
    &__kukuruzer {
        flex-grow: 1;
    }
    &__button-group {
        display: flex;
        font-size: 0;
        margin-right: -6px;
    }
}

.coin-list-item__action {
    padding: 0;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-color: transparent;
    background: #F6F6F6;
    margin: -6px 0;
    transition: .2s all ease;
    &:hover {
        border-color: $aurora_blue;
        background: $aurora_blue;
        svg {
            fill: #FFF;
        }
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
        transition: .2s fill ease;
        width: 18px;
        height: 18px;
        fill: #AAA;
    }

    .coin-list-item__button-group & {
        margin: 0;
        border-radius: 0;
        &:not(:hover) svg {
            fill: #9C9C9C;
        }
        &:first-child {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            svg {
                margin-right: -4px;
            }
        }
        &:last-child {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            svg {
                margin-left: -4px;
            }
        }
    }
}
