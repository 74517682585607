.confirmation {
    &__input {
        input, div {
            padding: 8px 12px;
            font-size: 18px;
            height: auto;
            line-height: 28px;
            border: none;
            border-radius: 0;
            font-family: "Ubuntu Mono";
            background: #F7F7F7;
            color: #606266;
        }
    }
    &__disclaimer {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 22px;
        margin-top: -8px;
        display: block;
        color: #959595;
        hyphens: auto;
        word-break: break-word;
        &--error {
            color: #cb0b0b;
            line-height: 1.6;
        }
    }
}

.active-view {
    display: flex;
    flex-direction: column;
    &__header {
        padding: 13px 14px 12px;
        background: #FFF;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #e4e7ed;
        .el-page-header__left::after {
            display: none;
        }
        .el-page-header__left {
            margin-right: 0;
            position: absolute;
        }
        .el-page-header__content {
            font-size: 16px;
            flex-grow: 1;
            text-align: center;
            padding: 0 62px 0 68px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__container {
        height: 100%;
    }
    &__body {
        padding: 14px 20px 0 20px;
        .el-form-item__label {
            margin: 2px 0 0 0;
        }
    }
    &__footer {
        border-top: 1px solid #e4e7ed;
        padding: 16px 20px 17px 20px;
        background: #F5F5F5;
        margin: 0 auto;
        position: relative;
        border-radius: 0 0 10px 10px;
        align-items: center;
        z-index: 2000;
        width: 100%;
        box-sizing: border-box;
        .button--fat + .button--fat {
            margin: 0 0 0 12px;
        }
    }
}

.modal-receive-qr {
    &__container {
        width: 100%;
        padding: 100% 0 0 0;
        position: relative;
    }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        image-rendering: crisp-edges;
    }
}
