.el-button--primary {
    &.is-disabled {
        &, &:hover, &:focus, &:active {
            background-color: lighten($aurora_blue, 25%);
            border-color: lighten($aurora_blue, 24%);
        }
    }
    &, &:focus {
        background-color: $aurora_blue;
        border-color: $aurora_blue; 
    }
    &:hover {
        background-color: darken($aurora_blue, 8%);
        border-color: darken($aurora_blue, 8%);
    }
    &:active {
        background-color: darken($aurora_blue, 12%);
        border-color: darken($aurora_blue, 12%);
    }
}
.el-button--small {
    padding: 10px 16px;
}

.el-input {
    width: 100%;
}
.el-input-number {
    max-width: 100%;
}
.el-textarea {
    &__inner {
        min-height: 40px !important;
    }
    .el-input__count {
        line-height: 16px;
    }
}

.el-popper {
    max-width: 380px;
    @media screen and (max-width: 420px) {
        & {
            max-width: calc(100% - 40px);
        }
    }
}

.el-page-header {
    &__title {
        font-weight: 400;
    }
}

.el-select-dropdown {
    &__item {
        &.selected {
            font-weight: 500;
        }
    }
}

.el-collapse-item--noarrow {
    .el-collapse-item__arrow {
        display: none;
    }
}

.el-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    text-align: center;
    outline: none !important;
    border: none !important;
    top: 25px !important;
    &__icon {
        font-size: 26px;
        color: #FFF !important;
    }
    &__content {
        color: #FFF !important;
        font-weight: 600;
        line-height: 22px;
    }
    &__closeBtn {
        position: relative;
        transform: unset;
        right: unset;
        color: lighten($aurora_red, 30%);
        &:hover {
            color: #FFF;
        }
    }
    &--error {
        background: $aurora_red;
    }
    &--success {
        background: darken($aurora_green, 5%);
    }
}

@media screen and (max-width: 480px) {
    .el-message {
        width: 100%;
        border-radius: 0;
        top: 0 !important;
    }
}

.el-dialog {
    border-radius: 10px;
    max-width: 380px;
    width: auto;
    &__header {
        padding: 0 20px;
        line-height: 1;
    }
    &__body {
        position: relative;
    }
    &__contents {
        padding: 10px 20px !important;
    }
    &__footer {
        text-align: center;
        background: #FFF;
        border-radius: 0 0 14px 14px;
        padding: 18px 20px 20px;
        &:empty {
            position: relative;
            padding: 4px;
        }
    }
    &--floating-bottom &__footer {
        position: sticky;
        bottom: 0;
        z-index: 300;
    }

    .packaged-macos & {
        margin-top: 20px + $macos_pseudo_titlebar_height !important;
    }

    @media screen and (max-width: 400px) {
        & {
            max-width: unset;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

.el-dialog--no-pad {
    .el-dialog__body {
        padding: 0;
    }
}

.el-autocomplete-suggestion li {
    padding: 0 12px !important; 
}

.el-message-box {
    max-width: 420px;
    border-radius: 10px;
    width: calc(100% - 24px);
    margin: 20px 12px;
    vertical-align: top;
}
