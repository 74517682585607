.el-dialog {
    .el-tabs {
        border-bottom: 1px solid #e4e7ed;
        &__header {
            margin: 0;
        }
        &__nav {
            display: flex;
            width: 100%;
        }
        &__nav-wrap {
            padding: 0 16px;
            &::after {
                display: none;
            }
        }
        &__active-bar {
            background: $aurora_blue;
        }
        &__item {
            flex: 1;
            text-align: center;
            line-height: 46px;
            height: 46px;
            color: #999;
            font-size: 16px;
            font-weight: 400;
            &.is-active {
                color: #031b4e;
            }
        }
    }

    .el-form-item__label {
        margin: 8px 0 8px 1px;
        color: #6b778c;
        font-size: 12px;
        line-height: 1;
    }
}

.wallet-dialog {
    .el-dialog {
        margin-top: calc(20px + env(safe-area-inset-top)) !important;
    }

    .el-tabs__content {
        display: none;
    }

    &__input-error {
        position: relative;
    }

    &__info {
        display: block;
        hyphens: auto;
        margin: -2px 0 18px 0;
        word-break: normal;
        line-height: 20px;
        border-radius: 0;
        padding: 6px 12px;
        font-size: 13px;
        background: #e6f3fb;
        color: #2f678d;
        border-left: 4px solid #6bb1e0;
    }

    .is-error + &__info {
        margin-top: 32px !important;
    }
}
